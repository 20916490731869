<script setup lang="ts">
import { useDialogs } from "@/vf"
import { UseMouse, vElementHover } from "@vueuse/components"
import { useScroll } from "@vueuse/core"
import { computed, ref } from "vue"
import TerpeneExplanationDialog from "@/shop/pages/article/TerpeneExplanationDialog.vue"
import type { Terpene } from "@/model/backend/terpene"

const isHovering = ref(false)
const { x: scrollX, y: scrollY } = useScroll(document)

const dialogs = useDialogs()

const props = withDefaults(
    defineProps<{
        terpene: Terpene
    }>(),
    {},
)

function openDialog() {
    dialogs.open({
        component: TerpeneExplanationDialog,
        props: { text: text.value },
    })
}

const text = computed(() => formatTerpeneInfo(props.terpene))

function formatTerpeneInfo(terpene: Terpene) {
    let text = ""
    if (terpene.occurrence) text += `<b>Vorkommen:</b> ${terpene.occurrence}\n`
    if (terpene.description) text += `<b>Eigenschaften:</b> ${terpene.description}`
    return text
}
</script>

<template>
    <div v-element-hover="hovering => (isHovering = hovering)" class="d-inline">
        <button type="button" class="border-0 bg-transparent" style="cursor: default" @click="openDialog()">
            <i class="fa fw-fa fa-circle-info text-primary"></i>
        </button>

        <UseMouse v-slot="{ x, y }">
            <div
                v-if="isHovering"
                class="popover popover-help"
                :style="{ position: 'fixed', top: `${y - scrollY}px`, left: `${x - scrollX}px` }"
            >
                <div class="popover-body" v-html="text" />
            </div>
        </UseMouse>
    </div>
</template>
