import { type ARTICLE_SUB_ITEM_VALUE, articleSupportsSubItem, validateSubItem } from "@/model/article-sub-items"
import type { Article } from "@/model/backend/article"
import type { ArticleSubItemType } from "@/model/backend/article-sub-item-type"
import { useHttpClient } from "@/vf"
import type { AxiosResponse } from "axios"
import { defineStore } from "pinia"
import { computed, reactive, ref, watch } from "vue"
import { type ShopAppConfig, useShopAppConfig } from "../composables"

type StockStatus = "not-in-stock" | "not-enough-stock" | "batch-overflow" | "ok"

export interface CartItem {
    article: Article
    amount: number
    singlePrice?: number
    totalPrice?: number
    tax?: number
    /** deprecated use stockStatus instead */
    // inStock?: boolean
    /** Can be undefined for special cart items (fees, shipping costs etc.) */
    stockStatus: StockStatus | undefined

    // packaging, humidity etc
    subItems?: CartItemSubitem[]

    // the total amount for all sub items, calculated by the server
    subItemsTotal?: number
}

export interface CartItemSubitem {
    type: ArticleSubItemType
    value: ARTICLE_SUB_ITEM_VALUE
}

// the item that the server sends us (CartController)
interface ServerItem {
    id: string // article id
    name: string
    amount: number
    singlePrice?: number
    totalPrice?: number
    type: "cannabis" | "standard" | "merch" | "essence" | "fee" | "shipping" | "discount" | "balance" | "custom"
    packageAmount: number
    tax: number
    stockStatus: StockStatus | undefined
    subItemsTotal: number
}

// the response from the server after we updated the cart (CartController)
interface CartUpdateResponse {
    totalPrice: number
    totalPaymentPrice: number
    totalItems: number
    totalTax: number
    totalTaxes: { tax: number; vat: 7 | 19 | number }[]
    totalCannabisAmount: number
    usedBalance: number
    items: ServerItem[]
}

export interface CartUpload {
    file: Blob
    url: string | undefined
    selectedAt: Date
}

const STORAGE_KEY = "cann24-cart"

export const useCartStore = defineStore("cart", () => {
    const { post } = useHttpClient()
    const { appConfig } = useShopAppConfig()
    const items = reactive<CartItem[]>([])
    const uploads = reactive<CartUpload[]>([])

    const containsPrescriptionItems = computed(() => items.some(i => i.article.requiresPrescription))
    const totalItems = computed(() => items.reduce((n, i) => n + i.amount, 0))

    const totalTaxes = ref<CartUpdateResponse["totalTaxes"]>()
    const totalTax = ref<number>()
    const totalCannabisAmount = ref<number>(0)
    const usedBalance = ref<number>()
    const serverItems = reactive<ServerItem[]>([])
    const paymentMethod = ref<string>()

    const totalPrice = ref<number>()
    const totalPaymentPrice = ref<number>()

    const shippingMethod = ref<string>()
    const shippingOptions = reactive<Record<string, string[]>>({})
    const prescriptionTransmission = ref<string>()

    const requestCounter = ref(0)

    const shippingAddress = reactive({
        firstName: "",
        lastName: "",
        street: "",
        city: "",
        zip: "",
        deliveryNote: "",
        additionalAddress: "",
    })

    function _getItemFromArticle(article: Article, raiseErrorWhenNotFound = true): CartItem | undefined {
        const item = items.find(i => i.article.id == article.id)
        if (!item && raiseErrorWhenNotFound) {
            throw Error("article not found")
        }

        return item
    }

    watch(appConfig, () => _loadFromLocalStorage(appConfig.value))
    _loadFromLocalStorage(appConfig.value)

    function _loadFromLocalStorage(config: ShopAppConfig) {
        if (config.offline) return
        if (config.user) {
            shippingAddress.firstName = config.user.firstName ?? ""
            shippingAddress.lastName = config.user.lastName ?? ""
            shippingAddress.street = config.user.street ?? ""
            shippingAddress.city = config.user.city ?? ""
            shippingAddress.zip = config.user.zip ?? ""
            shippingAddress.deliveryNote = config.user.deliveryNote ?? ""
            shippingAddress.additionalAddress = config.user.additionalAddress ?? ""
        }
        for (const method of config.shippingMethods) {
            shippingOptions[method.name] = []
        }
        // reload cart from storage
        let data
        try {
            data = localStorage.getItem(STORAGE_KEY)
        } catch (e) {
            // security exception. ignore
            return
        }

        // restore cart items from local storage
        if (data) {
            // clear cart
            items.splice(0, items.length)

            for (const item of JSON.parse(data)) {
                // find article for stored cart item
                const article = config.articles.find(i => i.id == item.id)

                if (!article) {
                    // article not found (maybe no longer available), ignore it
                    continue
                }
                // article was found, add it to the cart
                addToCart(article, item.amount || 1, false)

                // restore selected sub items (packaging, integra etc)
                for (const subitem of item.subitems ?? []) {
                    if (!subitem.type || !subitem.value) {
                        // invalid data structure, ignore
                        continue
                    }

                    if (!validateSubItem(subitem.type, subitem.value)) {
                        // type or value are not allowed values, ignroe
                        continue
                    }

                    if (!articleSupportsSubItem(article.type ?? "", subitem.type)) {
                        // article does not support this sub item, ignore
                        continue
                    }

                    // add sub item to cart item
                    changeSubitem(article, subitem.type, subitem.value)
                }
            }
            update().catch()
        }
    }

    function removeFromCart(article: Article): void {
        const item = _getItemFromArticle(article)

        if (!item) {
            return
        }

        items.splice(items.indexOf(item), 1)
        update().catch()
    }

    function addToCart(article: Article, amount: number, updateState = true): void {
        const item = _getItemFromArticle(article, false)

        if (item) {
            item.amount += amount
        } else {
            items.push({
                article,
                amount: amount,
                singlePrice: undefined,
                totalPrice: undefined,
                stockStatus: undefined,
                subItems: [],
            })
        }

        if (updateState) {
            update().catch()
        }
    }

    function changeSubitem(article: Article, type: ArticleSubItemType, value: ARTICLE_SUB_ITEM_VALUE) {
        const item = _getItemFromArticle(article)
        if (!item) {
            return
        }

        if (!item.subItems) {
            item.subItems = []
        }

        // remove the sub item type if it already exists
        item.subItems = item.subItems?.filter(i => i.type !== type)
        item.subItems.push({ type, value })

        update().catch()
    }

    function containsArticle(article: Article): boolean {
        return getCartItemForArticle(article) !== undefined
    }

    function getCartItemForArticle(article: Article): CartItem | undefined {
        return items.find(i => i.article.id == article.id)
    }

    function isMinimumAmount(article: Article) {
        const item = _getItemFromArticle(article, false)
        const minimum = article.type === "cannabis" ? 5 : 1

        return item?.amount === minimum
    }

    function changeAmount(article: Article, newAmount: number): void {
        const item = _getItemFromArticle(article)
        // const minimum = article.type === "cannabis" ? 5 : 1
        // if (newAmount < minimum) {
        //     removeFromCart(article)
        //     return
        // }

        if (!item) {
            return
        }

        item.amount = Math.max(0, newAmount)
        update().catch()
    }

    async function clear() {
        items.splice(0, items.length)
        uploads.splice(0, uploads.length)
        await update().catch()
    }

    async function update(params: { validatePendingOrders?: boolean } = {}): Promise<void> {
        if (!appConfig.value.user) {
            return
        }

        try {
            localStorage.setItem(
                STORAGE_KEY,
                JSON.stringify(
                    items.map(i => {
                        return {
                            id: i.article.id,
                            amount: i.amount,
                            subitems: i.subItems,
                        }
                    }),
                ),
            )
        } catch (e) {
            // security exception. ignore
        }

        // the server side uses normalized order items. split the original/special double item into separate ones
        const postArticles: {
            articleId: string
            amount: number
            packageAmount: number
            subItems: Record<string, string>[]
        }[] = []

        for (const item of items) {
            // invalidate all prices, we will get new ones from the server, this will show spinners where the prices
            // are normally displayed
            item.singlePrice = undefined
            item.totalPrice = undefined
            item.subItemsTotal = undefined
            item.stockStatus = "ok" // hide all warnings

            totalPrice.value = undefined
            totalPaymentPrice.value = undefined

            if (item.amount > 0 && (item.article.packageAmount ?? 0) > 0) {
                postArticles.push({
                    articleId: item.article.id!,
                    amount: item.amount,
                    packageAmount: item.article.packageAmount ?? 0,
                    subItems: item.subItems ?? [],
                })
            }
        }

        const sm = _getShippingMethodWithOptionsString()
        const currentRequest = ++requestCounter.value
        return post<CartUpdateResponse>("cart/calculate", {
            items: postArticles,
            shippingMethod: sm,
            ...params,
        }).then(r => {
            if (currentRequest !== requestCounter.value) {
                // we did not perform a new request since we started this one. use the result
                return
            }

            // this.totalItems = r.content.totalItems
            totalPrice.value = r.data.totalPrice
            totalPaymentPrice.value = r.data.totalPaymentPrice
            totalTaxes.value = r.data.totalTaxes
            totalTax.value = r.data.totalTax
            totalCannabisAmount.value = r.data.totalCannabisAmount
            usedBalance.value = r.data.usedBalance
            serverItems.splice(0, serverItems.length)
            for (const responseItem of r.data.items) {
                if (responseItem.type == "fee" || responseItem.type == "discount" || responseItem.type == "shipping") {
                    serverItems.push(responseItem)
                } else {
                    const cartItem = items.find(i => i.article.id == responseItem.id)
                    if (!cartItem) continue
                    if (responseItem.packageAmount == cartItem.article.packageAmount) {
                        cartItem.singlePrice = responseItem.singlePrice
                        cartItem.totalPrice = responseItem.totalPrice
                    } else {
                        throw new Error("response from server does not match our local package amounts")
                    }
                    cartItem.tax = responseItem.tax
                    cartItem.stockStatus = responseItem.stockStatus
                    cartItem.subItemsTotal = responseItem.subItemsTotal
                }
            }
        })
    }

    function _getShippingMethodWithOptionsString() {
        let sm = shippingMethod.value ?? ""
        if (shippingOptions[sm] && shippingOptions[sm].length > 0) {
            sm += "+" + shippingOptions[sm].join("+")
        }
        return sm
    }

    function submitOrder(): Promise<
        AxiosResponse<{
            url: string
            status: string
            orderId: string
        }>
    > {
        const form = new FormData() // form for file upload
        let i = 0
        for (const item of items) {
            if (item.amount > 0 && item.article.id) {
                form.append("items[" + i + "][articleId]", item.article.id)
                form.append("items[" + i + "][amount]", item.amount.toString())
                form.append("items[" + i + "][packageAmount]", item.article?.packageAmount?.toString() ?? "1")

                let subItemIndex = 0
                for (const subItem of item.subItems ?? []) {
                    form.append("items[" + i + "][subItems][" + subItemIndex + "][type]", subItem.type)
                    form.append("items[" + i + "][subItems][" + subItemIndex + "][value]", subItem.value)
                    subItemIndex++
                }

                i++
            }
        }
        i = 0
        for (const upload of uploads) {
            form.append("upload[" + i + "]", upload.file)
        }

        form.append("paymentMethod", paymentMethod.value ?? "")

        const sm = _getShippingMethodWithOptionsString()

        form.append("shippingMethod", sm)
        for (const [key, value] of Object.entries(shippingAddress)) {
            form.append("shippingAddress[" + key + "]", value)
        }

        if (prescriptionTransmission.value) {
            form.append("prescriptionTransmission", prescriptionTransmission.value)
        }

        return post("checkout/order", form)
    }

    const allSubitemsSelected = computed(() => {
        for (const item of items) {
            if (
                articleSupportsSubItem(item.article.type ?? "", "packaging") &&
                !item.subItems?.some(i => i.type == "packaging")
            ) {
                return false
            }

            if (
                articleSupportsSubItem(item.article.type ?? "", "humidity") &&
                !item.subItems?.some(i => i.type == "humidity")
            ) {
                return false
            }
        }
        return true
    })

    return {
        items,
        uploads,
        addToCart,
        changeSubitem,
        containsArticle,
        getCartItemForArticle,
        isMinimumAmount,
        changeAmount,
        clear,
        containsPrescriptionItems,
        totalItems,
        totalTaxes,
        totalTax,
        totalCannabisAmount,
        usedBalance,
        serverItems,
        totalPrice,
        totalPaymentPrice,
        shippingMethod,
        shippingOptions,
        shippingAddress,
        update,
        removeFromCart,
        paymentMethod,
        submitOrder,
        prescriptionTransmission,
        allSubitemsSelected,
    }
})
