<script setup lang="ts">
import type { DialogController } from "@/vf"
import { defineComponent, type RenderFunction } from "vue"

const props = defineProps<{
    controller: DialogController<void>
    content: RenderFunction
}>()

const contentComponent = defineComponent({
    setup() {
        return () => props.content()
    },
})
</script>

<template>
    <div class="dialog">
        <div class="dialog-body">
            <div>
                <contentComponent />
            </div>

            <button type="button" class="btn btn-primary mt-3" @click="controller.ok()">Schließen</button>
        </div>
    </div>
</template>
