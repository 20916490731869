<script setup lang="ts">
import CheckoutTimeline from "@/shop/components/checkout/CheckoutTimeline.vue"
import { useCartStore } from "@/shop/store"
import { computed } from "vue"

const cartStore = useCartStore()

const allowedZipsForCarrier = [
    21109, 21107, 20539, 20457, 21079, 21073, 22113, 21033, 22117, 22111, 22119, 20359, 20537, 20459, 22043, 22041,
    20097, 20099, 20095, 20354, 20355, 20357, 22767, 22769, 22765, 22763, 22605, 22761, 22525, 20535, 22087, 22089,
    22081, 22083, 22085,
].sort()

const addressComplete = computed(() => {
    return (
        cartStore.shippingAddress.firstName &&
        cartStore.shippingAddress.lastName &&
        cartStore.shippingAddress.street &&
        cartStore.shippingAddress.zip &&
        cartStore.shippingAddress.city
    )
})
</script>

<template>
    <div class="container my-5">
        <div class="small text-muted mb-5 pb-3">Home / Warenkorb</div>
        <h2>Übersicht</h2>
        <div class="mb-3">
            <h1>Lieferadresse</h1>
        </div>
        <CheckoutTimeline :step="4" />
        <h3 class="mt-4">Lieferadresse</h3>
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="firstName">Vorname</label>
                    <input
                        id="firstName"
                        v-model="cartStore.shippingAddress.firstName"
                        type="text"
                        class="form-control"
                        placeholder="Vorname"
                        required
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="lastName">Nachname</label>
                    <input
                        id="lastName"
                        v-model="cartStore.shippingAddress.lastName"
                        type="text"
                        class="form-control"
                        placeholder="Nachname"
                        required
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="street">Straße</label>
                    <input
                        id="street"
                        v-model="cartStore.shippingAddress.street"
                        type="text"
                        class="form-control"
                        placeholder="Straße"
                        required
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="additionalAddress">Adresszusatz</label>
                    <input
                        id="additionalAddress"
                        v-model="cartStore.shippingAddress.additionalAddress"
                        type="text"
                        class="form-control"
                        placeholder="Adresszusatz"
                        required
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label for="zip">PLZ</label>
                            <input
                                v-if="cartStore.shippingMethod !== 'carrier'"
                                id="zip"
                                v-model="cartStore.shippingAddress.zip"
                                type="text"
                                class="form-control"
                                placeholder="PLZ"
                                required
                            />
                            <select
                                v-if="cartStore.shippingMethod === 'carrier'"
                                id="zip"
                                v-model="cartStore.shippingAddress.zip"
                                class="form-control custom-select"
                            >
                                <option value></option>
                                <option v-for="zip in allowedZipsForCarrier" :key="zip" :value="zip">
                                    {{ zip }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <label for="city">Stadt</label>
                            <input
                                id="city"
                                v-model="cartStore.shippingAddress.city"
                                type="text"
                                class="form-control"
                                placeholder="Stadt"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="deliveryNote">Lieferhinweis</label>
                    <input
                        id="deliveryNote"
                        v-model="cartStore.shippingAddress.deliveryNote"
                        type="text"
                        class="form-control"
                        placeholder="Lieferhinweis"
                        required
                    />
                </div>
            </div>
        </div>

        <div class="text-right mt-4">
            <RouterLink v-if="addressComplete" class="btn btn-primary" :to="{ name: 'prescription' }">
                Weiter
            </RouterLink>
            <button v-else class="btn btn-primary" type="button" disabled>Weiter</button>
        </div>
    </div>
</template>
