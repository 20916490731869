<script lang="ts" setup>
import { deepCopy } from "@/vf"
import { findDataForName, findSchemaForName, getVfFormInject } from "@/vf/utils/SymfonyForm"
import { FormKitSchema } from "@formkit/vue"
import { inject, onMounted, ref, type Ref, toRef, watch } from "vue"
import VfFormRendered from "./VfFormRendered.vue"

const props = withDefaults(
    defineProps<{
        name?: string // if no name is supplied, a schema is *required*
        schema?: any // if no schema is supplied, a name is *required*
        path?: string // path in the schema if the form row is inside a FormKit element
        isRest?: boolean
        onlyMarkRendered?: boolean
        sectionsSchema?: any
        data?: any
        disabled?: boolean
        hideWhenMissing?: boolean
        filterOptionsSource?: any
        filterOptionsDisableHidden?: boolean
    }>(),
    {
        filterOptionsDisableHidden: true,
        filterOptionsSource: undefined,
        name: undefined,
        schema: undefined,
        path: undefined,
        sectionsSchema: undefined,
        data: undefined,
    },
)
const element = ref<HTMLSpanElement | null>(null)

const emit = defineEmits<{
    (
        e: "filterOptions",
        data: Ref,
        options: { key: string; value: string; additionalData?: object }[],
        updateOptions: (options: { key: string; value: string; additionalData?: object }[]) => void,
    ): { key: string; value: string }
}>()

const path = props.path ? toRef(props, "path") : inject<Ref<string | undefined>>("vf-form-group", ref(undefined))

// let label: string | null = null

// we *want* to lose reactivity here
// eslint-disable-next-line vue/no-setup-props-destructure
let schema = props.schema
// eslint-disable-next-line vue/no-setup-props-destructure
let data = props.data
const form = getVfFormInject()
if (!schema) {
    if (!props.name) {
        throw new Error("If no schema is provided to VfFormRow, a name is required.")
    }
    try {
        schema = deepCopy(findSchemaForName(props.name, "VfFormRow", path.value))
        data = findDataForName(props.name, "VfFormRow", form)
    } catch (e) {
        if (props.hideWhenMissing) {
            schema = null
            data = null
        } else {
            throw e
        }
    }
}
let showOptions: any

function hotfixVisibleOptions() {
    if (!element.value) return
    element.value
        .querySelectorAll("li.formkit-option, ul.formkit-options li, option.formkit-option")
        .forEach((el: Element) => {
            const elementType: string = el.nodeName
            switch (elementType) {
                case "LI":
                    {
                        const element = el as HTMLLIElement
                        const input = element.querySelector("input") as HTMLInputElement
                        const value = input.value
                        if (showOptions.find((o: any) => o.value === value)) {
                            element.style.display = "block"
                        } else {
                            element.style.display = "none"
                            if (props.filterOptionsDisableHidden) {
                                input.checked = false
                            }
                        }
                    }
                    break
                case "OPTION": {
                    const element = el as HTMLOptionElement
                    const parentEl = el.parentNode
                    const value = element.value
                    if (showOptions.find((o: any) => o.value === value)) {
                        element.style.display = "block"
                        element.disabled = false

                        /**
                         * Safari does not support display none on option
                         * option-element gets wrapped with a span element in order to force browser to hide the option element.
                         * https://zatta.link/en/web/option-element-display-none-behavior-on-sp-browser.html
                         */
                        if (parentEl?.nodeName === "SPAN") {
                            const parentElement = parentEl as HTMLSpanElement
                            const selectElement = parentElement.parentNode as HTMLSelectElement
                            selectElement.insertBefore(element, parentElement)
                            parentElement.remove()
                        }
                    } else {
                        element.style.display = "none"
                        element.disabled = true
                        if (props.filterOptionsDisableHidden) {
                            element.selected = false
                        }

                        /** Safari hack */
                        if (parentEl?.nodeName !== "SPAN") {
                            const span = document.createElement("span")
                            el.parentNode?.insertBefore(span, element)
                            span.appendChild(el)
                        }
                    }
                }
            }
        })
}

if (schema && schema.options) {
    const options = schema.options
    const varName = "$" + schema.name + "Options"
    schema.options = "$" + varName
    data.value[varName] = options
    showOptions = options
    const updateFilterOptions = () => {
        emit("filterOptions", data, options, newOptions => {
            showOptions = newOptions
            hotfixVisibleOptions()
        })
    }
    if (props.name) {
        watch(() => [findDataForName(props.name!, "VfFormRow", form), props.filterOptionsSource], updateFilterOptions, {
            immediate: true,
        })
    } else {
        updateFilterOptions()
    }
    onMounted(() => hotfixVisibleOptions())
    watch(element, () => hotfixVisibleOptions())
}

if (schema && props.sectionsSchema) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    schema.sectionsSchema = props.sectionsSchema
}

// this seems to be redundant, as we already have this in the :disabled prop binding in the view
// watch(
//     () => [props.disabled, props.name, props.schema],
//     () => {
//         schema.disabled = props.disabled
//     },
// )
</script>

<template>
    <template v-if="schema">
        <span ref="element">
            <FormKitSchema
                v-if="!props.onlyMarkRendered"
                v-bind="$attrs"
                :schema="schema"
                :data="data"
                :disabled="props.disabled || schema.disabled"
            />
        </span>
        <VfFormRendered v-if="!props.isRest" :name="props.name ?? schema.name"></VfFormRendered>
    </template>
</template>
