import type { DoctorQuestionnaire } from "@/model/backend/doctor-questionnaire"

/**
 * Type containing all answer fields from the questionnaire.
 */
export type QuestionnaireValues = Omit<
    DoctorQuestionnaire,
    "name" | "email" | "token" | "answeredAt" | "createdAt" | "expiresAt"
>

/**
 * The steps that are used in the wizard
 */
export enum QuestionnaireStep {
    IsGerman,
    // first name, last name, date of birth
    PersonalInfo,
    WorkingPlace,
    Address,
    // job title, doctor specialization
    JobInfo,
    CertificateOfApproval,
    OperatesTelemedicine,
    // non German only
    CountryAllowsTelemedicine,
    PersonalContact,
    ValidateIdentity,
    PrescriptionIndication,
    // non German only
    PrescriptionIndicationNonGerman,
    PrescriptionType,
    // non German only
    CountryAllowsCannabis,
    UploadSignature,
    AcceptDataprivacy,
}

export const questionLabelsByLanguage: Record<"en" | "de", Record<QuestionnaireStep, string>> = {
    de: {
        [QuestionnaireStep.IsGerman]: "In welchem Land üben Sie Ihre ärztliche Tätigkeit aus?",
        [QuestionnaireStep.PersonalInfo]: "Bitte geben Sie Ihre Daten ein",
        [QuestionnaireStep.WorkingPlace]: "Wie üben Sie Ihre ärztliche Tätigkeit aus?",
        [QuestionnaireStep.Address]: "Bitte geben Sie Ihre Adresse an",
        [QuestionnaireStep.JobInfo]: "Bitte geben Sie folgende Daten an",
        [QuestionnaireStep.CertificateOfApproval]:
            "Bitte laden Sie Ihre gültige Approbationsurkunde bzw. Berufserlaubnis hoch",
        [QuestionnaireStep.OperatesTelemedicine]: "Verschreiben Sie Cannabis-Präparate im Rahmen der Telemedizin?",
        [QuestionnaireStep.CountryAllowsTelemedicine]:
            "Bitte bestätigen Sie, dass die von Ihnen ausgeübte telemedizinische Tätigkeit in dem Mitgliedsstaat, von dem aus Sie die Tätigkeit ausüben, nicht untersagt ist",
        [QuestionnaireStep.PersonalContact]:
            "Findet bei telemedizinischer Erstverschreibung ein persönlicher Kontakt im Rahmen einer Videosprechstunde statt?",
        [QuestionnaireStep.ValidateIdentity]:
            "Bitte bestätigen Sie, dass Sie sich vor jeder Verschreibung ausreichend Informationen zur Identität der Person, für die das Arzneimittel bestimmt ist, verschafft haben und somit sichergestellt werden kann, dass eine ausschließliche Fernbehandlung ärztlich vertretbar ist.",
        [QuestionnaireStep.PrescriptionIndication]:
            "Bitte bestätigen Sie, dass Sie telemedizinische Erstverschreibungen nur vornehmen, wenn die allgemein anerkannten fachlichen Standards eine rein telemedizinische Behandlung im jeweiligen Indikationsbereich gestatten",
        [QuestionnaireStep.PrescriptionIndicationNonGerman]:
            "Bitte bestätigen Sie, dass in dem Mitgliedsstaat, von dem aus Sie die Tätigkeit ausüben, die Verschreibung von Cannabispräparaten erlaubt ist und insbesonder Sie über die entsprechende Erlaubnis verfügen, Cannabispräparate zu verschreiben",
        [QuestionnaireStep.PrescriptionType]: "Auf welchem Medium verschreiben Sie Cannabispräparate?",
        [QuestionnaireStep.CountryAllowsCannabis]:
            "Bitte bestätigen Sie, dass in dem Mitgliedsstaat, von dem aus Sie die Tätigkeit ausüben, die Verschreibung von Cannabispräparaten erlaubt ist und insbesondere Sie über die entspechende Erlaubnis verfügen, Cannabispräparate zu verschreiben.",
        [QuestionnaireStep.UploadSignature]: "Bitte laden Sie Ihre Unterschrift hoch",
        [QuestionnaireStep.AcceptDataprivacy]: "Bitte bestätigen Sie die Datenschutzerklärung",
    },
    en: {
        [QuestionnaireStep.IsGerman]: "In which country do you practice medicine?",
        [QuestionnaireStep.PersonalInfo]: "Please enter your data",
        [QuestionnaireStep.WorkingPlace]: "How do you practice medicine?",
        [QuestionnaireStep.Address]: "Please enter your address",
        [QuestionnaireStep.JobInfo]: "Please provide the following data",
        [QuestionnaireStep.CertificateOfApproval]: "Please upload your valid medical license or professional permit",
        [QuestionnaireStep.OperatesTelemedicine]: "Do you prescribe cannabis-based medications via telemedicine?",
        [QuestionnaireStep.CountryAllowsTelemedicine]:
            "Please confirm that your telemedicine practice is not prohibited in the member state from which you operate.",
        [QuestionnaireStep.PersonalContact]:
            "Does an initial telemedicine prescription involve a personal consultation via video call?",
        [QuestionnaireStep.ValidateIdentity]:
            "Please confirm that before each prescription, you have obtained sufficient information about the identity of the person for whom the medication is intended, ensuring that an exclusively remote treatment is medically justifiable.",
        [QuestionnaireStep.PrescriptionIndication]:
            "Please confirm that you only issue initial telemedicine prescriptions if generally accepted professional standards allow a purely telemedical treatment in the respective indication area.",
        [QuestionnaireStep.PrescriptionIndicationNonGerman]:
            "Please confirm that in the member state from which you operate, the prescription of cannabis-based medications is permitted and that you have the necessary authorization to prescribe them.",
        [QuestionnaireStep.PrescriptionType]: "On which medium do you prescribe cannabis-based medications?",
        [QuestionnaireStep.CountryAllowsCannabis]:
            "Please confirm that in the member state from which you operate, the prescription of cannabis-based medications is permitted and that you have the necessary authorization to prescribe them.",
        [QuestionnaireStep.UploadSignature]: "Please upload your signature",
        [QuestionnaireStep.AcceptDataprivacy]: "Please confirm the data privacy policy",
    },
}

// convert model structure ({isGerman: boolean, workingPlace: "office" | "private" | "both", ...})}) to a structure
// where each value is an object with the possible values as keys and the corresponding labels as values
// e.g. {isGerman: {true: "🇩🇪 Deutschland", false: "🇪🇺 Mitgliedsstaat der europäischen Union"}, ...}
type Transform<T> = {
    [K in keyof T]: T[K] extends string | number | boolean
        ? { [V in `${T[K]}`]: string } // Convert boolean values to string
        : never
}

const _answerLabelsDe = {
    isGerman: {
        true: "🇩🇪 Deutschland",
        false: "🇪🇺 Mitgliedsstaat der europäischen Union",
    },

    workingPlace: {
        office: "In eigener Praxis, einer Klinik oder einer sonstigen Gesundheitseinrichtung",
        private: "An meiner Privatadresse",
        both: "Sowohl als auch",
    },

    operatesTelemedicine: {
        yes: "Ja",
        no: "Nein, nur nach physischen Kontakt",
        both: "Sowohl als auch",
    },

    personalContactOnPrescription: {
        true: "Ja",
        false: "Nein",
    },

    validatesIdentityOnRemotePrescription: {
        true: "Ich bestätige",
        false: "Ich bestätige nicht",
    },

    remotePrescriptionIndicationConfirmation: {
        true: "Ich bestätige",
        false: "Ich bestätige nicht",
    },

    prescriptionType: {
        paper: "Auf Papier",
        digital: "Digital",
        both: "Sowohl als auch",
    },

    prescriptionAllowedInOtherMemberStates: {
        true: "Ich bestätige",
        false: "Ich bestätige nicht",
    },

    prescriptionMemberStateRemoteAllowed: {
        true: "Ich bestätige",
        false: "Ich bestätige nicht",
    },
} as const

const _answerLabelsEn = {
    isGerman: {
        true: "🇩🇪 Germany",
        false: "🇪🇺 Member state of the European Union",
    },
    workingPlace: {
        office: "In my own practice, a clinic, or another healthcare facility",
        private: "At my private address",
        both: "Both",
    },
    operatesTelemedicine: {
        yes: "Yes",
        no: "No, only after physical contact",
        both: "Both",
    },
    personalContactOnPrescription: {
        true: "Yes",
        false: "No",
    },
    validatesIdentityOnRemotePrescription: {
        true: "I confirm",
        false: "I do not confirm",
    },
    remotePrescriptionIndicationConfirmation: {
        true: "I confirm",
        false: "I do not confirm",
    },
    prescriptionType: {
        paper: "On paper",
        digital: "Digital",
        both: "Both",
    },
    prescriptionAllowedInOtherMemberStates: {
        true: "I confirm",
        false: "I do not confirm",
    },
    prescriptionMemberStateRemoteAllowed: {
        true: "I confirm",
        false: "I do not confirm",
    },
} as const

export const answerLabelsByLanguage: Record<
    "en" | "de",
    Required<Pick<Partial<Transform<Required<QuestionnaireValues>>>, keyof typeof _answerLabelsDe>>
> = {
    de: _answerLabelsDe,
    en: _answerLabelsEn,
}

/**
 * Which fields are filled in which step; this is useful to clear values from future steps when going back to a
 * previous step
 */
export const fieldsByStep: Record<QuestionnaireStep, (keyof QuestionnaireValues)[]> = {
    [QuestionnaireStep.IsGerman]: ["isGerman"],
    [QuestionnaireStep.PersonalInfo]: ["firstName", "lastName", "dateOfBirth"],
    [QuestionnaireStep.WorkingPlace]: ["workingPlace"],
    [QuestionnaireStep.Address]: [
        "privateStreet",
        "privateStreetNumber",
        "privateZip",
        "privateCity",
        "privatePhone",
        "officeStreet",
        "officeStreetNumber",
        "officeZip",
        "officeCity",
        "officePhone",
    ],
    [QuestionnaireStep.JobInfo]: [
        "jobTitle",
        "doctorSpecialization",
        "responsibleRegionalMedicalAssociation",
        "responsibleSupervisoryAuthority",
    ],
    [QuestionnaireStep.CertificateOfApproval]: ["certificateOfApproval"],
    [QuestionnaireStep.OperatesTelemedicine]: ["operatesTelemedicine"],
    [QuestionnaireStep.CountryAllowsTelemedicine]: ["prescriptionMemberStateRemoteAllowed"],
    [QuestionnaireStep.PersonalContact]: ["personalContactOnPrescription"],
    [QuestionnaireStep.ValidateIdentity]: ["validatesIdentityOnRemotePrescription"],
    [QuestionnaireStep.PrescriptionIndication]: ["remotePrescriptionIndicationConfirmation"],
    [QuestionnaireStep.PrescriptionIndicationNonGerman]: ["remotePrescriptionIndicationConfirmation"],
    [QuestionnaireStep.PrescriptionType]: ["prescriptionType"],
    [QuestionnaireStep.CountryAllowsCannabis]: ["prescriptionAllowedInOtherMemberStates"],
    [QuestionnaireStep.UploadSignature]: ["paperPrescriptionSignature", "digitalPrescriptionCertificate"],
    [QuestionnaireStep.AcceptDataprivacy]: [],
}
