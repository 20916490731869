<script setup lang="ts">
import type { DialogController } from "@/vf"
import { computed, h, isVNode, type VNode } from "vue"

const props = defineProps<{
    controller: DialogController<void, { title: string; message: string | VNode }>
}>()
const options = computed(() => props.controller.props)

const message = computed(() => {
    if (isVNode(options.value.message)) {
        return options.value.message
    }

    return h("p", null, options.value.message)
})
</script>

<template>
    <div>
        <div class="dialog-header">
            <h2>{{ options.title }}</h2>
        </div>

        <div class="dialog-body">
            <component :is="message"></component>
        </div>

        <div class="dialog-footer">
            <button type="button" class="btn btn-primary" @click="controller.ok()">OK</button>
        </div>
    </div>
</template>
