<script setup lang="ts">
import { useDialogs } from "@/vf"
import { UseMouse, vElementHover } from "@vueuse/components"
import { useScroll } from "@vueuse/core"
import { ref, useSlots } from "vue"
import ArticleExplanationDialog from "./ArticleExplanationDialog.vue"

const isHovering = ref(false)
const { x: scrollX, y: scrollY } = useScroll(document)

const slots = useSlots()

const dialogs = useDialogs()
function openDialog() {
    dialogs.open({
        component: ArticleExplanationDialog,
        props: {
            content: slots.default,
        },
    })
}
</script>

<template>
    <div v-element-hover="hovering => (isHovering = hovering)" class="d-inline">
        <button type="button" class="border-0 bg-transparent" style="cursor: default" @click="openDialog()">
            <i class="far fw-fa fa-question-circle text-primary"></i>
        </button>

        <UseMouse v-slot="{ x, y }">
            <div
                v-if="isHovering"
                class="popover popover-help"
                :style="{ position: 'fixed', top: `${y - scrollY}px`, left: `${x - scrollX}px` }"
            >
                <div class="popover-body">
                    <slot />
                </div>
            </div>
        </UseMouse>
    </div>
</template>
