<script setup lang="ts">
import { $time, useHttpClient } from "@/vf"
import type { Appointment } from "@/model/backend/appointment"
import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import { DateTime } from "luxon"
import { h } from "vue"

const props = defineProps<{
    appointments: Appointment[]
}>()

const dialogs = useShopDialogs()
const http = useHttpClient()

const emit = defineEmits<{
    (e: "reload"): void
}>()

async function decline(start: string, id: string) {
    if (DateTime.fromISO(start).diffNow("hours").hours <= 18) {
        await dialogs.alert(
            "Termin stornieren",
            h("div", null, [
                "Der Termin findet in weniger als 18 Stunden statt. Bitte setze dich telefonisch mit uns in Verbindung um den Termin zu stornieren. Telefon: ",
                h("a", { href: "tel:0403023869924" }, "040 30 23 86 99 24"),
            ]),
        )
        return
    }

    if (!(await dialogs.confirm("Termin stornieren", "Möchtest du den Termin wirklich absagen?"))) {
        return
    }

    await http.post(`/appointment/${id}/cancel`)

    emit("reload")

    await dialogs.alert("Termin storniert", "Der Termin wurde erfolgreich storniert.")
}
</script>
<template>
    <b>Dein nächster Termin bei uns</b>
    <div class="appointments">
        <template v-for="appointment in props.appointments" :key="appointment.id">
            <div class="appointment-card">
                <div class="date-icon">
                    <div class="day">
                        {{ DateTime.fromISO(appointment.start).toFormat("ccc", { locale: "de" }) }}
                    </div>
                    <div class="month">
                        {{ DateTime.fromISO(appointment.start).toFormat("dd. LLL", { locale: "de" }) }}
                    </div>
                </div>
                <div class="info">
                    <div class="time">{{ $time(appointment.start) }} Uhr</div>
                    <div class="duration">Dauer: {{ appointment.duration }} Minuten</div>
                </div>
                <button class="btn btn-outline-danger" @click="decline(appointment.start, appointment.id!)">
                    Termin absagen
                </button>
            </div>
        </template>
    </div>
</template>

<style scoped>
.appointments {
    max-width: 600px;
    margin: 1rem auto 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-family: system-ui, sans-serif;
}

.appointment-card {
    background: #fff;
    border-left: 6px solid var(--primary);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.date-icon {
    background: var(--primary);
    color: white;
    border-radius: 8px;
    padding: 0.5rem;
    text-align: center;
    width: 64px;
    min-width: 64px;
}

.date-icon .day {
    font-size: 1.2rem;
    font-weight: bold;
}

.date-icon .month {
    text-transform: uppercase;
    font-size: 0.75rem;
    opacity: 0.9;
}

.info {
    flex-grow: 1;
}

.time {
    font-weight: 600;
    font-size: 1rem;
}

.duration {
    font-size: 0.9rem;
    color: #555;
}

@media (max-width: 600px) {
    .appointment-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .date-icon {
        width: auto;
        min-width: unset;
        padding: 0.6rem 1rem;
        border-radius: 12px;
    }

    .time {
        font-size: 1.1rem;
    }

    .info .time::before {
        content: attr(data-time-only);
    }

    .info .time {
        content: none;
    }
}
</style>
