<script setup lang="ts">
import { presetImages } from "@/common/assets/preset-images/preset-images"
import { useAuthentication } from "@/shop/composables/useAuthentication"

const { login } = useAuthentication()
</script>

<template>
    <div class="container my-auto">
        <div class="row align-items-center flex-row-reverse flex-md-row">
            <div class="col-md-4">
                <div style="max-width: 50vw; margin: 0 auto">
                    <img :src="presetImages[2]" width="1026" height="1365" alt="CANN24" class="img-fluid" />
                </div>
            </div>
            <div class="col-md-8 mt-5 mt-md-0 px-4 px-md-0">
                <h1>Jetzt einloggen und mehr entdecken</h1>

                <p>
                    Bitte
                    <a href="#" @click="login">logge dich</a>
                    für weitere Informationen ein. Wenn du noch keinen Account hast, kannst du dich
                    <RouterLink :to="{ name: 'registration' }">hier registrieren.</RouterLink>
                </p>
            </div>
        </div>
    </div>
</template>
