<script setup lang="ts">
import {
    answerLabelsByLanguage,
    fieldsByStep,
    questionLabelsByLanguage,
    QuestionnaireStep,
    type QuestionnaireValues,
} from "@/common/util/questionnaire-definition"
import { useHttpClient } from "@/vf"
import { useFileDialog } from "@vueuse/core"
import { computed, reactive, ref } from "vue"
import Cleave from "vue-cleave-component"
import { useRouter } from "vue-router"
import infoPdf from "@/shop/assets/Anforderungen_an_Rezeptur-Verordnungen.pdf"
import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import type { AxiosError } from "axios"

const props = defineProps<{
    token: string
}>()

const http = useHttpClient()
const router = useRouter()
const dialogs = useShopDialogs()

/** Filter given type T to only include fields that have the value type ValueType. */
type FilterKeysByType<T, ValueType> = {
    [K in keyof T]: T[K] extends ValueType ? K : never
}[keyof T]

const state = ref<"open" | "submitting" | "invalid" | "error" | "done">("open")
const model = reactive<QuestionnaireValues>({})
const currentStep = ref(QuestionnaireStep.IsGerman)
const language = ref<"de" | "en">("de")
const submitError = ref<string>()

const answerLabels = computed(() => answerLabelsByLanguage[language.value])
const questionLabels = computed(() => questionLabelsByLanguage[language.value])

const translations = {
    de: {
        questionnaire: "Fragebogen",
        title: "Fragebogen für verschreibende Personen",
        continueLabel: "Weiter",
        submitLabel: "Absenden",
        submitted:
            "Vielen Dank! Wir haben Ihre Antworten erhalten und prüfen diese. Sobald die Prüfung abgeschlossen ist, erhalten Sie von uns eine E-Mail.",
        invalid: "Der angegebene Link ist ungültig. Bitte überprüfen Sie den Link und versuchen Sie es erneut.",
        error: "Entschuldigung. Leider konnte der Fragebogen nicht übertragen werden. Bitte versuchen Sie es später erneut. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an unseren Support.",
        submitting: "Vielen Dank. Ihre Antworten werden übertragen. Bitte die Seite nicht schließen.",
        tryAgain: "Erneut versuchen",
        bornAt: "geboren am",
        private: "Privat",
        office: "Praxis",
        selectFile: "Datei auswählen",
        fileSelected: "Datei ausgewählt",
        lastName: "Name",
        firstName: "Vorname",
        dateOfBirth: "Geburtsdatum",
        street: "Straße",
        streetNumber: "Hausnummer",
        zip: "PLZ",
        city: "Stadt",
        phone: "Telefonnummer",
        jobTitle: "Berufsbezeichnung",
        doctorSpecialization: "Fachrichtung",
        medicalAssociation: "Landesärztekammer",
        supervisoryAuthority: "Zuständige Aufsichtsbehörde",
        selectSignature: "Unterschrift wie auf dem Rezept hochladen",
        signatureSelected: "Unterschrift wie auf dem Rezept ausgewählt",
        selectCertificate:
            "Zertifikat der qualifizierten elektronischen Signatur inkl. der dort hinterlegten Unterschrift hochladen",
        certificateSelected:
            "Zertifikat der qualifizierten elektronischen Signatur inkl. der dort hinterlegten Unterschrift ausgewählt",
        confirmDataPrivacy0: "Hiermit bestätige ich, dass ich die",
        confirmDataPrivacy1: "Datenschutzerklärung",
        confirmDataPrivacy2: "gelesen habe und der Speicherung meiner Daten zustimme.",
        confirmInfoPdf0: "Hiermit bestätige ich, dass ich die",
        confirmInfoPdf1: "Anforderungen an Rezeptur-Verordnung",
        confirmInfoPdf2: "gelesen und zur Kenntnis genommen habe.",
        fileSizeError: "Die Datei ist zu groß. Bitte wählen Sie eine Datei, die kleiner als 100 MB ist.",
    },
    en: {
        questionnaire: "Questionnaire",
        title: "Questionnaire for prescribing persons",
        continueLabel: "Continue",
        submitLabel: "Submit",
        submitted:
            "Thank you! We have received your answers and are reviewing them. Once the review is complete, you will receive an email from us.",
        invalid: "The link provided is invalid. Please check the link and try again.",
        error: "Sorry. Unfortunately, the questionnaire could not be submitted. Please try again later. If the problem persists, please contact our support.",
        submitting: "Thank you. Your answers are being submitted. Please do not close the page.",
        tryAgain: "Try again",
        bornAt: "born on",
        private: "Private",
        office: "Office",
        selectFile: "Select file",
        fileSelected: "File selected",
        lastName: "Last name",
        firstName: "First name",
        dateOfBirth: "Date of birth",
        street: "Street",
        streetNumber: "House number",
        zip: "ZIP",
        city: "City",
        phone: "Phone number",
        jobTitle: "Job title",
        doctorSpecialization: "Medical specialization",
        medicalAssociation: "Regional medical association",
        supervisoryAuthority: "Responsible supervisory authority",
        selectSignature: "Upload signature as on the prescription",
        signatureSelected: "Signature as on the prescription selected",
        selectCertificate: "Upload certificate of qualified electronic signature including the signature stored there",
        certificateSelected:
            "Certificate of qualified electronic signature including the signature stored there selected",
        confirmDataPrivacy0: "I hereby confirm that I have read the",
        confirmDataPrivacy1: "data privacy policy",
        confirmDataPrivacy2: "and agree to the storage of my data.",
        confirmInfoPdf0: "I hereby confirm that I have read and acknowledged the",
        confirmInfoPdf1: "requirements for prescription orders",
        confirmInfoPdf2: ".",
        fileSizeError: "The file is too large. Please select a file that is smaller than 100 MB.",
    },
}

const t = computed(() => translations[language.value])

async function validate() {
    try {
        await http.get(`/doctor-questionnaire/${props.token}`)
        state.value = "open"
    } catch (error) {
        console.error(error)
        state.value = "invalid"
    }
}

await validate()

/** The top buttons of the wizard that shows all questions that are already answered */
const answeredQuestions = reactive<QuestionnaireStep[]>([])
const acceptDataprivacy = ref(false)
const acceptInfoPdf = ref(false)

function select<T extends keyof QuestionnaireValues>(field: T, value: QuestionnaireValues[T]) {
    if (field === "isGerman") {
        language.value = value ? "de" : "en"
    }

    model[field] = value
    nextStep()
}

function selectFile(field: FilterKeysByType<Required<QuestionnaireValues>, File>) {
    const upload = useFileDialog({
        accept: "image/jpg,application/pdf",
    })

    upload.onChange(fileList => {
        const file = fileList?.[0]

        if (!file) {
            return
        }

        if (file.size > 100 * 1024 * 1024) {
            dialogs.alert("Upload", t.value.fileSizeError)
            return
        }

        model[field] = file
        nextStep()
    })
    upload.open()
}

function stepValid(step: QuestionnaireStep) {
    switch (step) {
        case QuestionnaireStep.PersonalInfo:
            return (
                !!model.lastName &&
                !!model.firstName &&
                !!model.dateOfBirth &&
                model.dateOfBirth.match(/^\d{2}\.\d{2}\.\d{4}$/)
            )

        case QuestionnaireStep.WorkingPlace:
            return !!model.workingPlace

        case QuestionnaireStep.Address:
            if (model.workingPlace === "private" || model.workingPlace === "both") {
                return (
                    !!model.privateStreet &&
                    !!model.privateStreetNumber &&
                    !!model.privateZip &&
                    !!model.privateCity &&
                    !!model.privatePhone
                )
            }

            if (model.workingPlace === "office" || model.workingPlace === "both") {
                return (
                    !!model.officeStreet &&
                    !!model.officeStreetNumber &&
                    !!model.officeZip &&
                    !!model.officeCity &&
                    !!model.officePhone
                )
            }

            return false

        case QuestionnaireStep.JobInfo:
            if (model.isGerman) {
                return !!model.responsibleRegionalMedicalAssociation && !!model.jobTitle && !!model.doctorSpecialization
            } else {
                return !!model.responsibleSupervisoryAuthority && !!model.jobTitle && !!model.doctorSpecialization
            }

        case QuestionnaireStep.CertificateOfApproval:
            return !!model.certificateOfApproval

        case QuestionnaireStep.OperatesTelemedicine:
            return !!model.operatesTelemedicine

        case QuestionnaireStep.CountryAllowsTelemedicine:
            return model.prescriptionMemberStateRemoteAllowed !== undefined

        case QuestionnaireStep.PersonalContact:
            return model.personalContactOnPrescription !== undefined

        case QuestionnaireStep.ValidateIdentity:
            return model.validatesIdentityOnRemotePrescription !== undefined

        case QuestionnaireStep.PrescriptionIndication:
        case QuestionnaireStep.PrescriptionIndicationNonGerman:
            return model.remotePrescriptionIndicationConfirmation !== undefined

        case QuestionnaireStep.CountryAllowsCannabis:
            return model.prescriptionAllowedInOtherMemberStates !== undefined

        case QuestionnaireStep.PrescriptionType:
            return model.prescriptionType !== undefined

        case QuestionnaireStep.UploadSignature:
            return true

        case QuestionnaireStep.AcceptDataprivacy:
            return acceptDataprivacy.value && acceptInfoPdf.value
    }
}

function nextStep() {
    answeredQuestions.push(currentStep.value)
    switch (currentStep.value) {
        case QuestionnaireStep.IsGerman:
            currentStep.value = QuestionnaireStep.PersonalInfo
            break

        case QuestionnaireStep.PersonalInfo:
            currentStep.value = QuestionnaireStep.WorkingPlace
            break

        case QuestionnaireStep.WorkingPlace:
            currentStep.value = QuestionnaireStep.Address
            break

        case QuestionnaireStep.Address:
            currentStep.value = QuestionnaireStep.JobInfo
            break

        case QuestionnaireStep.JobInfo:
            currentStep.value = QuestionnaireStep.CertificateOfApproval
            break

        case QuestionnaireStep.CertificateOfApproval:
            currentStep.value = QuestionnaireStep.OperatesTelemedicine
            break

        case QuestionnaireStep.OperatesTelemedicine:
            if (model.isGerman) {
                if (model.operatesTelemedicine === "no") {
                    currentStep.value = QuestionnaireStep.PrescriptionType
                    break
                }

                currentStep.value = QuestionnaireStep.PersonalContact
            } else {
                if (model.operatesTelemedicine === "no") {
                    currentStep.value = QuestionnaireStep.CountryAllowsCannabis
                    break
                }

                currentStep.value = QuestionnaireStep.CountryAllowsTelemedicine
            }

            break

        case QuestionnaireStep.CountryAllowsTelemedicine:
            currentStep.value = QuestionnaireStep.PersonalContact
            break

        case QuestionnaireStep.PersonalContact:
            if (model.personalContactOnPrescription) {
                currentStep.value = QuestionnaireStep.PrescriptionIndication
            } else {
                currentStep.value = QuestionnaireStep.ValidateIdentity
            }
            break

        case QuestionnaireStep.ValidateIdentity:
            if (model.isGerman) {
                currentStep.value = QuestionnaireStep.PrescriptionIndication
            } else {
                currentStep.value = QuestionnaireStep.PrescriptionIndicationNonGerman
            }
            break

        case QuestionnaireStep.PrescriptionIndication:
        case QuestionnaireStep.PrescriptionIndicationNonGerman:
            if (model.isGerman) {
                currentStep.value = QuestionnaireStep.PrescriptionType
            } else {
                currentStep.value = QuestionnaireStep.CountryAllowsCannabis
            }

            break

        case QuestionnaireStep.CountryAllowsCannabis:
            currentStep.value = QuestionnaireStep.PrescriptionType
            break

        case QuestionnaireStep.PrescriptionType:
            currentStep.value = QuestionnaireStep.UploadSignature
            break

        case QuestionnaireStep.UploadSignature: {
            const needsPaper = model.prescriptionType === "paper" || model.prescriptionType === "both"
            const needsCertificate = model.prescriptionType === "digital" || model.prescriptionType === "both"

            if (needsPaper && !model.paperPrescriptionSignature) {
                break
            }

            if (needsCertificate && !model.digitalPrescriptionCertificate) {
                break
            }

            currentStep.value = QuestionnaireStep.AcceptDataprivacy
            break
        }

        case QuestionnaireStep.AcceptDataprivacy:
            submit()

            break
    }
}

function backToStep(step: QuestionnaireStep) {
    const index = answeredQuestions.indexOf(step)
    if (index === -1) {
        return
    }

    // Remove all questions after the selected step
    const removedQuestions = answeredQuestions.splice(index, answeredQuestions.length - index)

    // Reset all fields that are part of the removed questions
    for (const question of [currentStep.value, ...removedQuestions]) {
        for (const field of fieldsByStep[question]) {
            model[field] = undefined
        }
    }

    // Move to selected question
    currentStep.value = step
}

async function submit() {
    state.value = "submitting"

    try {
        const formData = new FormData()
        for (const key in model) {
            const value = model[key as keyof QuestionnaireValues]
            if (value instanceof File) {
                formData.append(key, value)
                continue
            }

            if (value === true) {
                formData.append(key, "1")
                continue
            }

            if (value === false) {
                formData.append(key, "0")
                continue
            }

            if (value === null || value === undefined) {
                continue
            }

            formData.append(key, value)
        }

        await http.post(`/doctor-questionnaire/${props.token}`, formData)
        state.value = "done"
    } catch (e) {
        const error = e as AxiosError<{ error: string }>
        submitError.value = error?.response?.data?.error ?? error.message
        console.error(error)
        if (error.response?.status === 500 && !error.response?.headers["Content-Length"]) {
            submitError.value += " (no content-length)"
        }

        state.value = "error"
    }
}
</script>

<template>
    <div class="d-flex flex-column flex-grow-1">
        <div class="customer-area-header">
            <div class="container">
                <div class="customer-area-breadcrumb">{{ t.questionnaire }}</div>
                <div class="customer-area-heading">
                    <h2>CANN24</h2>
                    <h1>{{ t.title }}</h1>
                </div>
            </div>
        </div>
        <div v-if="state === 'done'" class="container my-auto text-center">
            <div class="d-flex flex-column align-items-center my-5">
                <i class="fa fa-fw fa-thumbs-up fa-5x mb-5 text-primary"></i>
                <div class="h4">{{ t.submitted }}</div>
            </div>
        </div>
        <div v-if="state === 'invalid'" class="container my-auto text-center">
            <div class="d-flex flex-column align-items-center my-5">
                <i class="fa fa-fw fa-triangle-exclamation fa-5x mb-5 text-danger"></i>
                <div class="h4">{{ t.invalid }}</div>
            </div>
        </div>
        <div v-if="state === 'error'" class="container my-auto text-center">
            <div class="d-flex flex-column align-items-center my-5">
                <i class="fa fa-fw fa-triangle-exclamation fa-5x mb-5 text-danger"></i>
                <div class="h4">{{ t.error }}</div>

                <button class="wizard-button" @click="submit">{{ t.tryAgain }}</button>
                <div class="text-muted small mt-3">{{ submitError }}</div>
            </div>
        </div>
        <div v-if="state === 'submitting'" class="container my-auto text-center">
            <div class="d-flex flex-column align-items-center my-5">
                <i class="fa fa-fw fa-circle-notch fa-spin fa-5x mb-5 text-primary"></i>
                <div class="h4">{{ t.submitting }}</div>
            </div>
        </div>
        <div v-if="state === 'open'" class="container pt-5">
            <div
                v-for="step of answeredQuestions"
                :key="step"
                class="wizard-button d-flex align-items-center pl-0 mt-0 mb-4"
                @click="backToStep(step)"
            >
                <i class="fa fa-fw fa-chevron-left px-4"></i>
                <div>
                    <b>{{ questionLabels[step] }}</b>

                    <template v-if="step === QuestionnaireStep.IsGerman">
                        <div class="small">{{ answerLabels.isGerman[`${model.isGerman!}`] }}</div>
                    </template>

                    <template v-if="step === QuestionnaireStep.PersonalInfo">
                        <div class="small">
                            <div>
                                {{ model.lastName }}, {{ model.firstName }}, {{ t.bornAt }} {{ model.dateOfBirth }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.WorkingPlace">
                        <div class="small">
                            <div>{{ answerLabels.workingPlace[model.workingPlace!] }}</div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.Address">
                        <div class="small">
                            <div v-if="model.workingPlace === 'private' || model.workingPlace === 'both'">
                                <span v-if="model.workingPlace === 'both'">{{ t.private }}</span>
                                {{ model.privateStreet }} {{ model.privateStreetNumber }}, {{ model.privateZip }}
                                {{ model.privateCity }}, {{ model.privatePhone }}
                            </div>
                            <div v-if="model.workingPlace === 'office' || model.workingPlace === 'both'">
                                <span v-if="model.workingPlace === 'both'">{{ t.office }}:</span>
                                {{ model.officeStreet }} {{ model.officeStreetNumber }}, {{ model.officeZip }}
                                {{ model.officeCity }}, {{ model.officePhone }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.JobInfo">
                        <div class="small">
                            {{ model.jobTitle }}, {{ model.doctorSpecialization }},
                            {{ model.responsibleRegionalMedicalAssociation ?? model.responsibleSupervisoryAuthority }}
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.CertificateOfApproval">
                        <div class="small">
                            <i class="fa fa-fw fa-check"></i>
                            {{ t.fileSelected }}
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.OperatesTelemedicine">
                        <div class="small">
                            <div>
                                {{ answerLabels.operatesTelemedicine[`${model.operatesTelemedicine!}`] }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.CountryAllowsTelemedicine">
                        <div class="small">
                            <div>
                                {{
                                    answerLabels.prescriptionMemberStateRemoteAllowed[
                                        `${model.prescriptionMemberStateRemoteAllowed!}`
                                    ]
                                }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.PersonalContact">
                        <div class="small">
                            <div>
                                {{
                                    answerLabels.personalContactOnPrescription[
                                        `${model.personalContactOnPrescription!}`
                                    ]
                                }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.ValidateIdentity">
                        <div class="small">
                            <div>
                                {{
                                    answerLabels.validatesIdentityOnRemotePrescription[
                                        `${model.validatesIdentityOnRemotePrescription!}`
                                    ]
                                }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.PrescriptionIndication">
                        <div class="small">
                            <div>
                                {{
                                    answerLabels.remotePrescriptionIndicationConfirmation[
                                        `${model.remotePrescriptionIndicationConfirmation!}`
                                    ]
                                }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.PrescriptionIndicationNonGerman">
                        <div class="small">
                            <div>
                                {{
                                    answerLabels.remotePrescriptionIndicationConfirmation[
                                        `${model.remotePrescriptionIndicationConfirmation!}`
                                    ]
                                }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.CountryAllowsCannabis">
                        <div class="small">
                            <div>
                                {{
                                    answerLabels.prescriptionAllowedInOtherMemberStates[
                                        `${model.prescriptionAllowedInOtherMemberStates!}`
                                    ]
                                }}
                            </div>
                        </div>
                    </template>

                    <template v-if="step === QuestionnaireStep.PrescriptionType">
                        <div class="small">
                            <div>
                                {{ answerLabels.prescriptionType[model.prescriptionType!] }}
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <h3>{{ questionLabels[currentStep] }}</h3>

            <div v-if="currentStep === QuestionnaireStep.IsGerman">
                <button type="button" class="wizard-button" @click="select('isGerman', true)">
                    {{ answerLabels.isGerman.true }}
                </button>
                <button type="button" class="wizard-button" @click="select('isGerman', false)">
                    {{ answerLabels.isGerman.false }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.PersonalInfo">
                <div class="mb-3">
                    {{ t.lastName }}:
                    <input v-model="model.lastName" type="text" class="form-control" />
                </div>
                <div class="mb-3">
                    {{ t.firstName }}:
                    <input v-model="model.firstName" type="text" class="form-control" />
                </div>
                <div class="mb-3">
                    {{ t.dateOfBirth }}:
                    <Cleave
                        v-model="model.dateOfBirth"
                        class="form-control"
                        placeholder="TT.MM.JJJJ"
                        :options="{
                            date: true,
                            datePattern: ['d', 'm', 'Y'],
                            delimiter: '.',
                        }"
                        :raw="false"
                    ></Cleave>
                </div>

                <button
                    type="button"
                    class="wizard-button mt-5"
                    :disabled="!stepValid(QuestionnaireStep.PersonalInfo)"
                    @click="nextStep()"
                >
                    {{ t.continueLabel }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.WorkingPlace">
                <button type="button" class="wizard-button" @click="select('workingPlace', 'office')">
                    {{ answerLabels.workingPlace.office }}
                </button>
                <button type="button" class="wizard-button" @click="select('workingPlace', 'private')">
                    {{ answerLabels.workingPlace.private }}
                </button>
                <button type="button" class="wizard-button" @click="select('workingPlace', 'both')">
                    {{ answerLabels.workingPlace.both }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.Address">
                <div v-if="model.workingPlace === 'private' || model.workingPlace === 'both'">
                    <div class="mb-3">
                        {{ t.private }} {{ t.street }}:
                        <input v-model="model.privateStreet" type="text" class="form-control" />
                    </div>
                    <div class="mb-3">
                        {{ t.private }} {{ t.streetNumber }}:
                        <input v-model="model.privateStreetNumber" type="text" class="form-control" />
                    </div>
                    <div class="mb-3">
                        {{ t.private }} {{ t.zip }}:
                        <input v-model="model.privateZip" type="text" class="form-control" />
                    </div>
                    <div class="mb-3">
                        {{ t.private }} {{ t.city }}:
                        <input v-model="model.privateCity" type="text" class="form-control" />
                    </div>
                    <div class="mb-3">
                        {{ t.private }} {{ t.phone }}:
                        <input v-model="model.privatePhone" type="text" class="form-control" />
                    </div>
                </div>

                <hr v-if="model.workingPlace === 'both'" class="my-5" />

                <div v-if="model.workingPlace === 'office' || model.workingPlace === 'both'">
                    <div class="mb-3">
                        {{ t.office }} {{ t.street }}:
                        <input v-model="model.officeStreet" type="text" class="form-control" />
                    </div>
                    <div class="mb-3">
                        {{ t.office }} {{ t.streetNumber }}:
                        <input v-model="model.officeStreetNumber" type="text" class="form-control" />
                    </div>
                    <div class="mb-3">
                        {{ t.office }} {{ t.zip }}:
                        <input v-model="model.officeZip" type="text" class="form-control" />
                    </div>
                    <div class="mb-3">
                        {{ t.office }} {{ t.city }}:
                        <input v-model="model.officeCity" type="text" class="form-control" />
                    </div>
                    <div class="mb-3">
                        {{ t.office }} {{ t.phone }}:
                        <input v-model="model.officePhone" type="text" class="form-control" />
                    </div>
                </div>

                <button
                    type="button"
                    :disabled="!stepValid(QuestionnaireStep.Address)"
                    class="wizard-button mt-5"
                    @click="nextStep()"
                >
                    {{ t.continueLabel }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.JobInfo">
                <div class="mb-3">
                    {{ t.jobTitle }}
                    <input v-model="model.jobTitle" type="text" class="form-control" />
                </div>
                <div class="mb-3">
                    {{ t.doctorSpecialization }}:
                    <input v-model="model.doctorSpecialization" type="text" class="form-control" />
                </div>

                <div v-if="model.isGerman" class="mb-3">
                    {{ t.medicalAssociation }}:
                    <select v-model="model.responsibleRegionalMedicalAssociation" class="custom-select">
                        <option
                            v-for="assoc in [
                                'Bayerische Landesärztekammer',
                                'Hessische Landesärztekammer',
                                'Landesärztekammer Baden-Württemberg',
                                'Landesärztekammer Brandenburg',
                                'Landesärztekammer Bremen',
                                'Landesärztekammer Rheinland-Pfalz',
                                'Landesärztekammer Thüringen',
                                'Niedersächsische Landesärztekammer',
                                'Saarländische Ärztekammer',
                                'Sächsische Landesärztekammer',
                                'Ärztekammer Berlin',
                                'Ärztekammer Hamburg',
                                'Ärztekammer Mecklenburg-Vorpommern',
                                'Ärztekammer Nordrhein',
                                'Ärztekammer Sachsen-Anhalt',
                                'Ärztekammer Schleswig-Holstein',
                                'Ärztekammer Westfalen-Lippe',
                            ] as const"
                            :key="assoc"
                            :value="assoc"
                        >
                            {{ assoc }}
                        </option>
                    </select>
                </div>

                <div v-if="!model.isGerman" class="mb-3">
                    {{ t.supervisoryAuthority }}:
                    <input v-model="model.responsibleSupervisoryAuthority" type="text" class="form-control" />
                </div>

                <button
                    type="button"
                    :disabled="!stepValid(QuestionnaireStep.JobInfo)"
                    class="wizard-button mt-5"
                    @click="nextStep()"
                >
                    {{ t.continueLabel }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.CertificateOfApproval">
                <div class="mb-3">
                    <button class="upload-button" @click="selectFile('certificateOfApproval')">
                        <i class="fa fa-fw fa-upload"></i>
                        <div>{{ t.selectFile }}</div>
                    </button>
                </div>
            </div>
            <div v-if="currentStep === QuestionnaireStep.OperatesTelemedicine">
                <button type="button" class="wizard-button" @click="select('operatesTelemedicine', 'yes')">
                    {{ answerLabels.operatesTelemedicine.yes }}
                </button>
                <button type="button" class="wizard-button" @click="select('operatesTelemedicine', 'no')">
                    {{ answerLabels.operatesTelemedicine.no }}
                </button>
                <button type="button" class="wizard-button" @click="select('operatesTelemedicine', 'both')">
                    {{ answerLabels.operatesTelemedicine.both }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.CountryAllowsTelemedicine">
                <button
                    type="button"
                    class="wizard-button"
                    @click="select('prescriptionMemberStateRemoteAllowed', true)"
                >
                    {{ answerLabels.prescriptionMemberStateRemoteAllowed.true }}
                </button>
                <button
                    type="button"
                    class="wizard-button"
                    @click="select('prescriptionMemberStateRemoteAllowed', false)"
                >
                    {{ answerLabels.prescriptionMemberStateRemoteAllowed.false }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.PersonalContact">
                <button type="button" class="wizard-button" @click="select('personalContactOnPrescription', true)">
                    {{ answerLabels.personalContactOnPrescription.true }}
                </button>
                <button type="button" class="wizard-button" @click="select('personalContactOnPrescription', false)">
                    {{ answerLabels.personalContactOnPrescription.false }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.ValidateIdentity">
                <button
                    type="button"
                    class="wizard-button"
                    @click="select('validatesIdentityOnRemotePrescription', true)"
                >
                    {{ answerLabels.validatesIdentityOnRemotePrescription.true }}
                </button>
                <button
                    type="button"
                    class="wizard-button"
                    @click="select('validatesIdentityOnRemotePrescription', false)"
                >
                    {{ answerLabels.validatesIdentityOnRemotePrescription.false }}
                </button>
            </div>
            <div
                v-if="
                    currentStep === QuestionnaireStep.PrescriptionIndication ||
                    currentStep === QuestionnaireStep.PrescriptionIndicationNonGerman
                "
            >
                <button
                    type="button"
                    class="wizard-button"
                    @click="select('remotePrescriptionIndicationConfirmation', true)"
                >
                    {{ answerLabels.remotePrescriptionIndicationConfirmation.true }}
                </button>
                <button
                    type="button"
                    class="wizard-button"
                    @click="select('remotePrescriptionIndicationConfirmation', false)"
                >
                    {{ answerLabels.remotePrescriptionIndicationConfirmation.false }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.CountryAllowsCannabis">
                <button
                    type="button"
                    class="wizard-button"
                    @click="select('prescriptionAllowedInOtherMemberStates', true)"
                >
                    {{ answerLabels.prescriptionAllowedInOtherMemberStates.true }}
                </button>
                <button
                    type="button"
                    class="wizard-button"
                    @click="select('prescriptionAllowedInOtherMemberStates', false)"
                >
                    {{ answerLabels.prescriptionAllowedInOtherMemberStates.false }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.PrescriptionType">
                <button type="button" class="wizard-button" @click="select('prescriptionType', 'paper')">
                    {{ answerLabels.prescriptionType.paper }}
                </button>
                <button type="button" class="wizard-button" @click="select('prescriptionType', 'digital')">
                    {{ answerLabels.prescriptionType.digital }}
                </button>
                <button type="button" class="wizard-button" @click="select('prescriptionType', 'both')">
                    {{ answerLabels.prescriptionType.both }}
                </button>
            </div>
            <div v-if="currentStep === QuestionnaireStep.UploadSignature">
                <div class="mb-3">
                    <button
                        v-if="model.prescriptionType === 'paper' || model.prescriptionType === 'both'"
                        class="upload-button"
                        :class="{ selected: model.paperPrescriptionSignature }"
                        @click="selectFile('paperPrescriptionSignature')"
                    >
                        <template v-if="!model.paperPrescriptionSignature">
                            <i class="fa fa-fw fa-upload"></i>
                            <div>{{ t.selectSignature }}</div>
                        </template>
                        <template v-else>
                            <i class="fa fa-fw fa-check"></i>
                            <div>{{ t.signatureSelected }}</div>
                        </template>
                    </button>
                    <button
                        v-if="model.prescriptionType === 'digital' || model.prescriptionType === 'both'"
                        class="upload-button"
                        :class="{ selected: model.digitalPrescriptionCertificate }"
                        @click="selectFile('digitalPrescriptionCertificate')"
                    >
                        <template v-if="!model.digitalPrescriptionCertificate">
                            <i class="fa fa-fw fa-upload"></i>
                            <div>
                                {{ t.selectCertificate }}
                            </div>
                        </template>
                        <template v-else>
                            <i class="fa fa-fw fa-check"></i>
                            <div>
                                {{ t.certificateSelected }}
                            </div>
                        </template>
                    </button>
                </div>
            </div>
            <div v-if="currentStep === QuestionnaireStep.AcceptDataprivacy">
                <label class="mt-3">
                    <input v-model="acceptDataprivacy" type="checkbox" />
                    {{ t.confirmDataPrivacy0 }}
                    <a :href="router.resolve({ name: 'legal-data-privacy' }).fullPath" target="_blank">
                        {{ t.confirmDataPrivacy1 }}
                    </a>
                    {{ t.confirmDataPrivacy2 }}
                </label>

                <label class="mt-3">
                    <input v-model="acceptInfoPdf" type="checkbox" />
                    {{ t.confirmInfoPdf0 }}
                    <a :href="infoPdf" target="_blank">{{ t.confirmInfoPdf1 }}</a>
                    {{ t.confirmInfoPdf2 }}
                </label>

                <button
                    type="button"
                    :disabled="!stepValid(QuestionnaireStep.AcceptDataprivacy)"
                    class="wizard-button mt-5"
                    @click="nextStep()"
                >
                    {{ t.submitLabel }}
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.upload-button {
    background-color: var(--primary-light);
    border: 1px solid var(--primary-light);
    color: #000;
    padding: 1rem 2rem;
    border-radius: 5px;
    transition: border-color 0.3s;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
}

.upload-button.selected {
    background-color: var(--success);
    border-color: var(--success);
    color: #fff;
}

.upload-button:hover {
    border-color: var(--primary);
}
</style>
