<script setup lang="ts">
import { VfDialogContainer } from "@/vf"
import { RouterView, useRouter } from "vue-router"
import OverlayContainer from "./components/OverlayContainer.vue"
import PageFooter from "./components/page-footer/PageFooter.vue"
import PageNavbar from "./components/page-navbar/PageNavbar.vue"
import { useShopAppConfig } from "./composables"
import OfflinePage from "./pages/offline/OfflinePage.vue"
import { useOverlayStore, useRequestUserInfoStore } from "./store"
import EnterShopOverlay from "./components/overlays/EnterShopOverlay.vue"
import { ref, watch } from "vue"

const { appConfig } = useShopAppConfig()

// initialize the store. this opens the overlay if necessary
useRequestUserInfoStore()

const router = useRouter()
const enterShopDialogShown = ref(false)

// show the enter shop dialog if the route requires it (which is the default). Show it only once and make sure
// it is shown when the user navigates to a page that requires it.
watch(router.currentRoute, () => {
    if (!appConfig.value.customEnterShopDialog) {
        return
    }
    if (router.currentRoute.value.meta?.enterDialog === false) {
        return
    }

    if (enterShopDialogShown.value) {
        return
    }

    const overlay = useOverlayStore()
    overlay.open(EnterShopOverlay)

    enterShopDialogShown.value = true
})
</script>

<template>
    <template v-if="appConfig.offline">
        <OfflinePage />
    </template>
    <template v-else>
        <PageNavbar />
        <div class="router-view">
            <RouterView v-slot="{ Component }">
                <!-- <Transition> -->
                <Suspense>
                    <component :is="Component" />
                </Suspense>
                <!-- </Transition> -->
            </RouterView>
        </div>

        <PageFooter />
        <OverlayContainer />
        <VfDialogContainer />
    </template>
</template>
